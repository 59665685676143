import React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import CardListTeam from '../components/CardListTeam'
import CardTeam from '../components/CardTeam'
import CardTeamFull from '../components/CardTeamFull'

const TeamContainer = styled.div`
  border-top: 2px solid #000;
  width: 100%;
`

const ContentTeam = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulTeam (sort: { order: ASC, fields: order }) {
        edges {
          node {
            id
            title
            email
            slug
            teambild {
              title
              fluid (maxWidth: 700, maxHeight: 500, cropFocus: FACE, resizingBehavior: CROP, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }            
              ogimg: resize(width: 1800) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.allContentfulTeam.edges

  return (
    <TeamContainer>
      <CardListTeam>
        <CardTeamFull />
        {posts.map(({ node: post }) => (
          <CardTeam key={post.id} {...post} />
        ))}
      </CardListTeam>
    </TeamContainer>
  )
}

export default ContentTeam
