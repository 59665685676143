import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

const Post = styled.li`
  position: relative;
  margin: 0 0 0 0;
  width: 100%;
  max-width: 100%;
  transition: background-color 0.2s;
  border-bottom: 2px solid #000;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 50%')};
    max-width: 50%;
    margin: 0 0 0 0;
  }
  &:hover {
    background-color: ${props => props.theme.colors.background};
  }
  > a .gatsby-image-wrapper {
    height: 0;
    padding-bottom: 500px;
  }
  &:nth-of-type(even):nth-last-of-type(2) {
    border-bottom: none;
  }
  &:last-of-type {
    border-bottom: none;
  }
`

const ImageHolder = styled.div`
  overflow: hidden;
`

const StyledImg = styled(Img)`
  transition: transform 0.03s ease-in;
  &:hover {
    transform: scale(1.1);
  }
`

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
  border-top: 2px solid #000;
  padding: 10px 15px;
  text-transform: uppercase;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    padding: 20px 30px;
  }
`

const Title = styled.h2`
  font-size: ${props => props.theme.fontSizes[3]}px;
  font-weight: 400;
  text-transform: capitalize;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: ${props => props.theme.fontSizes[5]}px;
  }
`

const Mail = styled.a`
  font-size: ${props => props.theme.fontSizes[3]}px;
  font-weight: 400;
  border: 2px solid #000;
  padding: 2px 25px;
  display: inline-block;
  border-radius: 99px;
  opacity: 1 !important;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: ${props => props.theme.fontSizes[5]}px;
    padding: 5px 50px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.text};
    color: ${props => props.theme.colors.background};
  }
`

const CardTeam = ({ slug, teambild, title, email, ...props }) => {
  return (
    <>
      {teambild && title && (
        <Post featured={props.featured}>
          <Link to={`/team/${slug}/`}>
            <ImageHolder>
              <StyledImg fluid={teambild.fluid} backgroundColor={'#eeeeee'} />
            </ImageHolder>
          </Link>
          <Details>
            <Link to={`/team/${slug}/`}>
              <Title>{title}</Title>
            </Link>
            <Mail href={`mailto:${email}`}>mail</Mail>
          </Details>
        </Post>
      )}
    </>
  )
}

export default CardTeam
