import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContainerHero from '../components/ContainerHero'
import ContainerContent from '../components/ContainerContent'
import ContentTeam from '../components/ContentTeam'
import SEO from '../components/SEO'
import HeroLeftStandard from '../components/HeroLeftStandard'
import HeroCenterStandard from '../components/HeroCenterStandard'
import Newsletter from '../components/NewsletterPopup.js'

const Produkte = () => {
  return (
    <Layout>
      <Newsletter />
      <SEO title="Das Team" description="Das EXTENDIT Team stellt sich vor." />
      <Container>
        <ContainerHero>
          <HeroLeftStandard>
            <PageTitle>Das Team</PageTitle>
          </HeroLeftStandard>
          <HeroCenterStandard>
            Wir kombinieren Kompetenz mit hoher Servicequalität und
            Freundlichkeit. Das Team von EXTENDIT steht gerne für Sie bereit!
          </HeroCenterStandard>
        </ContainerHero>
        <ContainerContent>
          <ContentTeam />
        </ContainerContent>
      </Container>
    </Layout>
  )
}

export default Produkte
