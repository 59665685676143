import React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

const Team = styled.li`
  position: relative;
  margin: 0 0 0 0;
  width: 100%;
  transition: background-color 0.2s;
  border: none;
  border-bottom: 2px solid #000;
  flex: 0 0 100%;
  margin: 0 0 0 0;
`

const StyledImg = styled(Img)`
  width: 100%;
`

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: ${props => props.theme.fontSizes[4]}px;
  line-height: 1;
  border-top: 2px solid #000;
  padding: 30px 15px;
  text-transform: uppercase;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    padding: 30px 60px;
    font-size: ${props => props.theme.fontSizes[7]}px;
  }
`

const CardTeamFull = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "Teamfoto.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400, maxHeight: 800, cropFocus: ENTROPY, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Team>
      <StyledImg
        fluid={data.file.childImageSharp.fluid}
        backgroundColor={'#eeeeee'}
      />
      <Details>Wir im Detail</Details>
    </Team>
  )
}

export default CardTeamFull
